<template>
  <span> {{ direction }}° <img ref="imgRef" :src="arrowUrl" /></span>
</template>
<script>
import MonitorBase from '@/views/monitor/monitorBase'
export default {
  name: 'Direction',
  mixins: [MonitorBase],
  props: {
    direction: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  watch: {
    direction(newVal, oldVal) {
      this.setDirection(newVal)
    }
  },
  mounted() {
    this.setDirection(this.direction)
  },
  methods: {
    setDirection(val) {
      this.setArrow(this.$refs.imgRef, val)
    }
  }
}
</script>
<style lang="less" scoped>
img {
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
}
span {
  display: flex;
  align-items: center;
}
</style>
